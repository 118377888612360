import money from 'money-math'
import styled from 'styled-components'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import { useLocales } from '@sevenrooms/core/locales'
import { useChangedReservationInfoWithPaylinkFlow } from '../../components/payment/hooks'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { submitButtonLocales } from './SubmitButton.locales'

interface SubmitButtonProps {
  onSubmit: () => void
  isLoading: boolean
}
export function SubmitButton({ onSubmit, isLoading }: SubmitButtonProps) {
  const { formatMessage } = useLocales()
  const [isEditWithOutstandingPaylinkFlow] = useChangedReservationInfoWithPaylinkFlow()
  const { isEditMode } = useStoreSelector(state => state.bookDetailsState)
  const {
    cardEntryOption,
    chargeAmount,
    chargeTotal,
    internalUpsellsEnabled,
    takePaymentOrSave,
    outstandingAddFlowTransaction,
    paylinkAutoCancel,
    outstandingPaylinkTransaction,
    override,
  } = useStoreSelector(state => state.bookPaymentState)
  const { isDirty: isUpgradesChanged } = useStoreSelector(state => state.upgradesState)

  let initialPaylinkAmount
  if (outstandingPaylinkTransaction) {
    initialPaylinkAmount = internalUpsellsEnabled
      ? money.floatToAmount(outstandingPaylinkTransaction.amount_decimal ?? 0)
      : money.floatToAmount(outstandingPaylinkTransaction.base_amount_decimal ?? 0)
  }

  const amount = internalUpsellsEnabled ? chargeTotal : chargeAmount

  const hasOutstandingSaveCardPaylink = outstandingAddFlowTransaction && outstandingAddFlowTransaction.amount === 0

  let isSendPaylink =
    takePaymentOrSave !== 'none' &&
    !isEditWithOutstandingPaylinkFlow &&
    cardEntryOption === 'paylink' &&
    ((takePaymentOrSave === 'take' && money.isPositive(amount ?? '')) ||
      (takePaymentOrSave === 'save' && !hasOutstandingSaveCardPaylink) ||
      (isUpgradesChanged && money.isPositive(amount ?? '')))

  // special case for when the user is editing a reservation with an outstanding paylink and override checked
  // We still want to send it if the amount has changed or paylink timer has changed
  const isPaylinkAmountChanged = amount === initialPaylinkAmount
  const isPaylinkAutoCancelTimeChanged =
    outstandingPaylinkTransaction?.paylink_auto_cancel_minutes &&
    paylinkAutoCancel === outstandingPaylinkTransaction?.paylink_auto_cancel_minutes
  if (isSendPaylink && isEditMode && override && isPaylinkAmountChanged && isPaylinkAutoCancelTimeChanged) {
    isSendPaylink = false
  }

  return (
    <BookButton data-test="sr-button-book" onClick={onSubmit} disabled={isLoading}>
      {((): JSX.Element | string => {
        if (isLoading) {
          return <PulsatingDots />
        } else if (isEditMode) {
          return isSendPaylink
            ? formatMessage(submitButtonLocales.saveReservationWithPaylink)
            : formatMessage(submitButtonLocales.saveReservation)
        }
        return isSendPaylink
          ? formatMessage(submitButtonLocales.bookReservationWithPaylink)
          : formatMessage(submitButtonLocales.bookReservation)
      })()}
    </BookButton>
  )
}

const BookButton = styled.button`
  ${props => props.theme.primaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 100%;
`
